import React from 'react';
import { Box } from '@mui/material';
import { trackEvent } from '@surfline/web-common';
import { useMount } from 'react-use';

import { fetchSwellEvents } from 'actions/swellEvents';
import ForecastHeader from 'components/ForecastHeader';
import { type PageType, PageTypes } from 'components/ForecastHeader/hooks/usePageDetails';
import SwellEventCardContainer from 'components/SwellEventCardContainer/SwellEventCardContainer';
import { getSpotDetails, getSpotReportData } from 'selectors/spot';
import getSwellEvents from 'selectors/swellEvents';
import { getSubregionOverviewData, useSubregionForecastArticles } from 'selectors/subregion';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import type { SwellEvent } from 'types/swellEvents';

import styles from './SpotPageContainer.module.scss';

const SpotPageContainer: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const forecastArticles = useSubregionForecastArticles();
  const spot = useAppSelector(getSpotDetails);
  const spotReportViewData = useAppSelector(getSpotReportData);
  const subregionOverview = useAppSelector(getSubregionOverviewData);
  const swellEvents = useAppSelector(getSwellEvents);
  const subregionId = spot && spot.subregion ? spot.subregion._id : null;

  const onClickSwellEventCard = (swellEvent: SwellEvent) => {
    trackEvent('Clicked Swell Alert Card', {
      title: swellEvent.name,
      contentType: 'Swell Alert',
      basins: swellEvent.basins.join(','),
      locationCategory: 'Spot Page - Top',
      destinationUrl: swellEvent.permalink,
      subregionId,
    });
  };

  useMount(() => {
    if (spot) {
      dispatch(fetchSwellEvents(spot._id, 'spot'));
    }
  });

  return (
    <Box className={styles.pageWrapper}>
      <section className="sl-section-container">
        {swellEvents.length ? (
          <SwellEventCardContainer onClickCard={onClickSwellEventCard} events={swellEvents} />
        ) : null}
        <ForecastHeader
          conditions={spotReportViewData?.conditions?.value}
          hasLiveCam={spot?.cameras?.length > 0}
          pageTitle={`${spot?.name} Surf Report`}
          pageType={PageTypes.SPOT as PageType}
          spot={spot}
          subregionOverview={subregionOverview}
          forecastArticles={forecastArticles}
        />
      </section>
      {children}
    </Box>
  );
};

export default SpotPageContainer;
