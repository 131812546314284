import { parseISO, isAfter, isBefore, endOfDay } from 'date-fns';

type LimitedTimePremiumPlusConfig = {
  start: string;
  end: string;
};

const computeLimitedTimePremiumPlusEligibility = (
  limitedTimePremiumPlusConfig: LimitedTimePremiumPlusConfig | null | undefined,
): boolean => {
  if (
    !limitedTimePremiumPlusConfig ||
    !limitedTimePremiumPlusConfig.start ||
    !limitedTimePremiumPlusConfig.end
  ) {
    return false;
  }

  const { start, end } = limitedTimePremiumPlusConfig;
  const currentDate = new Date();
  const currentDateUTC = new Date(currentDate.toISOString());
  const startDate = parseISO(start);
  const endDate = endOfDay(parseISO(end));
  const startDateUTC = new Date(startDate.toISOString());
  const endDateUTC = new Date(endDate.toISOString());

  return isAfter(currentDateUTC, startDateUTC) && isBefore(currentDateUTC, endDateUTC);
};

export default computeLimitedTimePremiumPlusEligibility;
